<template>
  <div class="guidelines-menu-wrapper" ref="sidebarWrapper">
    <h6 class="text-center">{{translations['sections']}}</h6>
    <div class="guidelines-menu" ref="sidebarMenu">
      <VuePerfectScrollbar class="scroll-area" :settings="settings">
        <div class="guide-sections">
          <div v-for="section in sections" :key="section.id" :id="`sidebarSection_${section.id}`"
               :class="{is_active: activeSectionId === section.id}"
               class="sidebar-link f_sidebarLink">
            <a href="javascript:void(0)" class="guide"
               @click="selectSection(section.id)">
              <span class="section-title">{{section.sectionTitle}}</span>
              <span class="title">{{section.title}}</span>
              <img src="../assets/images/chevron-right-icon.svg" class="right-chevron" alt=">"/>
            </a>
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
    <div class="disclaimer" v-if="activeGuide.description">
      {{activeGuide.description}}
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'GuidSectionsList',
  components: {
    VuePerfectScrollbar,
  },
  mounted() {
    this.getSections();
    window.addEventListener('scroll', this.setSidebarPosOnScroll);
    window.onresize = this.getSidebarParams;
  },
  destroyed() {
    window.removeEventListener('scroll', this.setSidebarPosOnScroll);
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      sidebarParams: {},
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    translations() {
      return this.$store.getters.getTranslations;
    },
    guideId() {
      return this.$route.params.guideId;
    },
    sections() {
      return this.$store.getters.getGuideSectionsList;
    },
    activeSectionId() {
      return this.$store.getters.getActiveSectionId;
    },
    activeGuide() {
      return this.$store.getters.getActiveGuide;
    },
  },
  watch: {
    guideId() {
      this.getSections();
    },
    lang() {
      this.getSections();
    },
    activeSectionId() {
      this.scrollSidebarActiveLinkToTop();
    },
  },
  methods: {
    getSections() {
      this.$store.dispatch('getGuideSectionsList', { lang: this.lang, id: this.guideId }).then(() => {
        if (!this.activeSectionId && this.$route.hash) {
          const id = parseInt(this.$route.hash.slice(1), 10);
          this.setActiveSectionId(id);
          this.scrollToBlock();
        }
        if (!this.$route.hash && this.sections.length) {
          this.selectSection(this.sections[0].id);
        }

        this.getSidebarParams();
      });
    },
    selectSection(id) {
      this.setActiveSectionId(id);
      this.setRouteHash(id);
      this.scrollToBlock();
    },
    setActiveSectionId(id) {
      this.$store.commit('setActiveSectionId', id);
    },
    setRouteHash(id) {
      this.$router.replace({ name: this.$route.name, params: { guideId: this.$route.params.guideId }, hash: `#${id}` });
    },
    scrollToBlock() {
      const block = document.getElementById(this.activeSectionId);
      if (block) {
        setTimeout(() => {
          window.scrollTo(0, block.offsetTop);
          this.scrollSidebarActiveLinkToTop();
        }, 500);
      }
    },
    scrollSidebarActiveLinkToTop() {
      const sidebar = this.$refs.sidebarMenu.querySelector('section');
      const block = document.getElementById(`sidebarSection_${this.activeSectionId}`);
      if (block) {
        if (window.outerWidth > 768) {
          const offset = block.offsetTop - sidebar.offsetTop;
          sidebar.scrollTo(0, offset);
        } else {
          block.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          });
        }
      }
    },
    getSidebarParams() {
      this.sidebarParams = this.$refs.sidebarWrapper.getBoundingClientRect();
    },
    setSidebarPosOnScroll() {
      const HEADER_HEIGHT = 130;
      const footerRect = document.getElementById('footer').getBoundingClientRect();
      const sidebarCurrentTop = this.$refs.sidebarWrapper.getBoundingClientRect().top;
      const footerTopSlip = footerRect.top - this.sidebarParams.bottom + HEADER_HEIGHT;

      if (footerRect.top < this.sidebarParams.bottom
        || sidebarCurrentTop < this.sidebarParams.top) {
        this.$refs.sidebarWrapper.style.top = `${footerTopSlip > HEADER_HEIGHT ? HEADER_HEIGHT : footerTopSlip}px`;
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/styles/views/guideSidebar.scss";
</style>
